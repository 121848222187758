import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import logo from '../../../public/logo.png';
import { useRouter } from 'next/router';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const router = useRouter();

    return (
        <div className="bg-white shadow-md">
            <div className="container mx-auto flex flex-wrap items-center justify-between px-4 xl:px-0 py-4">
                <Link href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <Image src={logo} alt="Logo" className="h-10 w-60" />
                </Link>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    aria-controls="navbar-solid-bg"
                    aria-expanded={isOpen}
                >
                    <span className="sr-only">Open main menu</span>
                    {!isOpen && (
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 17 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1h15M1 7h15M1 13h15"
                            />
                        </svg>
                    )}
                    {isOpen && (
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    )}
                </button>
                <div
                    className={`${isOpen ? "block" : "hidden"} w-full lg:block lg:w-auto`}
                    id="navbar-solid-bg"
                >
                    <ul className="flex flex-col items-center font-medium mt-4 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse lg:flex-row lg:mt-0 lg:border-0 lg:bg-transparent dark:bg-gray-800 lg:dark:bg-transparent dark:border-gray-700">
                        <li>
                            <Link href="/" passHref>
                                <p
                                    className={`block py-2 px-3 rounded md:p-0 ${router.pathname === "/"
                                        ? "text-[#0060b1]"
                                        : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#0060b1] dark:text-white md:dark:hover:text-[#0060b1] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                                        }`}
                                    aria-current={router.pathname === "/" ? "page" : undefined}
                                >
                                    Home
                                </p>
                            </Link>
                        </li>
                        <li>
                            <Link href="/aboutUs" passHref>
                                <p
                                    className={`block py-2 px-3 rounded md:p-0 ${router.pathname === "/aboutUs"
                                        ? "text-[#0060b1]"
                                        : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#0060b1] dark:text-white md:dark:hover:text-[#0060b1] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                                        }`}
                                    aria-current={router.pathname === "/aboutUs" ? "page" : undefined}
                                >
                                    About Us
                                </p>
                            </Link>
                        </li>
                        <li>
                            <Link href="/services" passHref>
                                <p
                                    className={`block py-2 px-3 rounded md:p-0 ${router.pathname === "/services"
                                        ? "text-[#0060b1]"
                                        : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#0060b1] dark:text-white md:dark:hover:text-[#0060b1] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                                        }`}
                                    aria-current={router.pathname === "/services" ? "page" : undefined}
                                >
                                    Our Services
                                </p>
                            </Link>
                        </li>
                        <li>
                            <Link href="/contactUs" passHref>
                                <p
                                    className={`block py-2 px-3 rounded md:p-0 ${router.pathname === "/contactUs"
                                        ? "text-[#0060b1]"
                                        : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#0060b1] dark:text-white md:dark:hover:text-[#0060b1] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                                        }`}
                                    aria-current={router.pathname === "/contactUs" ? "page" : undefined}
                                >
                                    Contact Us
                                </p>
                            </Link>
                        </li>
                        <div className='pl-4 py-2 lg:py-0'>
                            <Link href="/careers">
                                <button
                                    type="button"
                                    className="text-white bg-[#0060b1] hover:bg-[#004a7c] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                                >
                                    Careers
                                </button>
                            </Link>
                        </div>
                    </ul>

                </div>
            </div>
        </div>
    );
};

export default Navbar;
