import React from 'react';
// import { ImFacebook2 } from "react-icons/im";
// import { FaLinkedin } from "react-icons/fa";
// import { FaSquareXTwitter } from "react-icons/fa6";
// import { FaSquareInstagram } from "react-icons/fa6";
import Link from 'next/link';
// import { motion } from 'framer-motion';
import { CiMail } from "react-icons/ci";
import { AiOutlinePhone } from "react-icons/ai";

const Footer = () => {
    // const iconVariants = {
    //     hover: {
    //         scale: 1.2,
    //         transition: {
    //             duration: 0.3,
    //             ease: "easeInOut"
    //         }
    //     }
    // };

    return (
        <div>
            <div className="bg-[#0060b1] py-10">
                <div className="container mx-auto text-start px-4 xl:px-0">
                    <div className="flex flex-wrap">

                        <div className="w-full md:w-1/2 lg:w-1/3  mb-8">
                            <h2 className="text-lg md:text-xl font-semibold mb-4 md:mb-6 text-white uppercase">Quick Links</h2>
                            <ul className="space-y-2">
                                <li><Link href="/"><p className="text-white text-sm hover:underline">Home</p></Link></li>
                                <li><Link href="/aboutUs"><p className="text-white text-sm hover:underline">About Us</p></Link></li>
                                <li><Link href="/services"><p className="text-white text-sm hover:underline">OUR SERVICES FLAGGERS</p></Link></li>
                                <li><Link href="/contactUs"><p className="text-white text-sm hover:underline">Contact Us</p></Link></li>
                                <li><Link href="/careers"><p className="text-white text-sm hover:underline">Careers</p></Link></li>
                            </ul>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/3  mb-8">
                            <h2 className="text-lg md:text-xl font-semibold mb-4 md:mb-6 text-white uppercase">Location</h2>
                            <ul className="space-y-2">
                                <li>
                                    <a href="https://www.google.com/maps?q=4226A+White+Plains+Road,+Bronx,+New+York+10466" target="_blank"  className="flex items-center text-sm text-white hover:underline">
                                        4226A White Plains Road 3rd Floor Bronx, New York 10466
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/3  mb-8">
                            <h2 className="text-lg md:text-xl font-semibold mb-4 md:mb-6 text-white uppercase">SALES/SERVICE CONTACT</h2>
                            {/* <div className='flex space-x-4 mt-2 sm:mt-0'>
                                <a href='https://www.facebook.com/yourpage' target='_blank' rel='noopener noreferrer'>
                                    <motion.div variants={iconVariants}
                                        whileHover="hover" className='text-lg sm:text-2xl text-white'>
                                        <ImFacebook2 />
                                    </motion.div>
                                </a>
                                <a href='https://www.linkedin.com/in/yourprofile' target='_blank' rel='noopener noreferrer'>
                                    <motion.div variants={iconVariants}
                                        whileHover="hover" className='text-lg sm:text-2xl text-white'>
                                        <FaLinkedin />
                                    </motion.div>
                                </a>
                                <a href='https://twitter.com/yourprofile' target='_blank' rel='noopener noreferrer'>
                                    <motion.div variants={iconVariants}
                                        whileHover="hover" className='text-lg sm:text-2xl text-white'>
                                        <FaSquareXTwitter />
                                    </motion.div>
                                </a>
                                <a href='https://www.instagram.com/yourprofile' target='_blank' rel='noopener noreferrer'>
                                    <motion.div variants={iconVariants}
                                        whileHover="hover" className='text-lg sm:text-2xl text-white'>
                                        <FaSquareInstagram />
                                    </motion.div>
                                </a>
                            </div> */}
                            {/* <p className="mt-4 text-lg font-semibold text-white">SALES/SERVICE CONTACT</p> */}
                            <p className="mt-4 text-sm text-white flex items-center">
                            <span className='pr-2'><AiOutlinePhone /></span>
                                <a href="tel:347-630-1399" className=" hover:underline">
                                    347-630-1399
                                </a>
                            </p>
                            <p className="mt-4 text-sm text-white flex items-center">
                                <span className='pr-2'><CiMail /></span>
                                <a href="mailto:ronald@workforce7.com" className=" hover:underline">
                                    ronald@workforce7.com
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white py-4">
                <div className="container mx-auto px-4 xl:px-0">
                    <div className="flex items-center justify-between ">
                        <h2 className="text-sm md:text-base font-normal">
                            Copyright 2024 - Workforce7
                        </h2>
                        <div className='flex'>
                            <Link href="/aboutUs"><p className="text-sm md:text-base hover:underline pr-2">About Us</p></Link> |
                            <Link href="/contactUs"><p className="text-sm md:text-base hover:underline pl-2">Contact Us</p></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
