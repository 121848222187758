import React from 'react';
// import { ImFacebook2 } from "react-icons/im";
// import { FaLinkedin } from "react-icons/fa";
// import { FaSquareXTwitter } from "react-icons/fa6";
// import { FaSquareInstagram } from "react-icons/fa6";
import { AiOutlinePhone } from "react-icons/ai";
import { CiMail } from "react-icons/ci";
import { motion } from 'framer-motion';

const Header = () => {
    const iconVariants = {
        hover: {
            scale: 1.2,
            transition: {
                duration: 0.3,
                ease: "easeInOut"
            }
        }
    };
    return (
        <div className='bg-[#0060b1] p-2 sm:p-4'>
            <div className='container mx-auto flex flex-col sm:flex-row justify-between items-center'>
                <div className='flex flex-col sm:flex-row items-center sm:space-x-6'>
                    <a href="tel:914-358-9200" className='text-white text-sm sm:text-lg font-bold flex items-center'>
                        <motion.span
                            className='pr-2'
                            variants={iconVariants}
                            whileHover="hover"
                        >
                            <AiOutlinePhone />
                        </motion.span>
                        914-358-9200 Ext no: 1
                    </a>
                </div>
                <div className='flex space-x-4 mt-2 sm:mt-0'>
                <a href="mailto:ronald@workforce7.com" className='text-white text-sm sm:text-lg font-bold flex items-center mt-2 sm:mt-0'>
                        <motion.span
                            className='pr-2'
                            variants={iconVariants}
                            whileHover="hover"
                        >
                            <CiMail />
                        </motion.span>
                        ronald@workforce7.com
                    </a>
                    {/* <a href='https://www.facebook.com/yourpage' target='_blank' rel='noopener noreferrer'>
                        <motion.div variants={iconVariants}
                            whileHover="hover" className='text-lg sm:text-2xl text-white'>
                            <ImFacebook2 />
                        </motion.div>
                    </a>
                    <a href='https://www.linkedin.com/in/yourprofile' target='_blank' rel='noopener noreferrer'>
                        <motion.div variants={iconVariants}
                            whileHover="hover" className='text-lg sm:text-2xl text-white'>
                            <FaLinkedin />
                        </motion.div>
                    </a>
                    <a href='https://twitter.com/yourprofile' target='_blank' rel='noopener noreferrer'>
                        <motion.div variants={iconVariants}
                            whileHover="hover" className='text-lg sm:text-2xl text-white'>
                            <FaSquareXTwitter />
                        </motion.div>
                    </a>
                    <a href='https://www.instagram.com/yourprofile' target='_blank' rel='noopener noreferrer'>
                        <motion.div variants={iconVariants}
                            whileHover="hover" className='text-lg sm:text-2xl text-white'>
                            <FaSquareInstagram />
                        </motion.div>
                    </a> */}
                </div>
            </div>
        </div>
    );
}

export default Header;
